import { RecipesStore } from 'src/recipes/store/RecipesStore'
import { RootStore } from 'src/stores/RootStore'
import { computed, observable, action, runInAction, reaction, override } from 'mobx'
import { RecipeRowVM } from './RecipeRowVM'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { Recipe } from '../../aggregate/Recipe'
import { RecipeGroupRowVM } from './RecipeGroupRowVM'
import { RecipeNewRowVM } from './RecipeNewRowVM'

export class RecipesListVM extends ListVM<RootStore, Recipe, RecipeGroupRowVM, RecipeRowVM, RecipeNewRowVM> {
  private filterTextBox: any

  constructor(rootStore: RootStore) {
    super(rootStore, RecipeRowVM, RecipeGroupRowVM, RecipeNewRowVM)
    this.stickyHeaders = true
    this.autoGrow = true
    this.startCollapsed = this.rootStore.recipesStore.currentBoardRecords.length > 15
    this.setGroups('recipeCategoryNames', '')
    this.addReaction(
      reaction(
        () => this.finalListHeight,
        () => this.setHeight(this.finalListHeight),
        { delay: 200 }
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.recipesStore.recordJustUpdated,
        () => this.reloadRows()
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.recipeCategoriesStore.recordJustUpdated,
        () => this.reloadRows()
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.boardsStore.currentBoardId,
        () => this.reloadRows()
      )
    )
    this.setHeight(this.finalListHeight)
  }

  protected rowVMSortFunction = (a: RecipeRowVM, b: RecipeRowVM) => {
    const aVal = a.sortName
    const bVal = b.sortName
    if (aVal < bVal) return -1
    if (aVal > bVal) return 1
    return
  }

  protected aggregateSortFunction = (a: Recipe, b: Recipe): number => {
    const aVal = a.Name.toLowerCase()
    const bVal = b.Name.toLowerCase()
    if (aVal < bVal) return -1
    if (aVal > bVal) return 1
    return 0
  }

  @computed
  public get isSeeding(): boolean {
    return this.rootStore.appStore.isSeeding
  }

  @computed
  private get finalListHeight() {
    let offset = 0
    if (this.filterShown) offset = 56
    if (this.rootStore.appStore.isIos) offset += -2
    return this.rootStore.appStore.listHeight - offset
  }

  @computed
  public get finalListHeightPx() {
    return this.finalListHeight + 'px'
  }

  @computed
  public get loadProgress() {
    return this.rootStore.loadProgress
  }

  @computed
  public get progressHidden(): boolean {
    return this.rootStore.progressHidden
  }

  @action
  public openImport(e?: any) {
    const url = '/botsessionnew/recipe-from-url-v1'
    if (e?.ctrlKey) {
      window.open(url, '_blank')
      return
    }
    setTimeout(() => this.rootStore.appStore.navigateTo(url), 300)
  }

  @action
  public collapseAll() {
    this.setScrollTop(0)
    setTimeout(() => this.expandCollapseAll(), 100)
  }

  @action
  public toggleFilter() {
    this.filterShown = !this.filterShown
    if (this.filterShown) {
      setTimeout(() => {
        if (!this.filterTextBox) return
        this.filterTextBox.focus()
      }, 500)
    }
  }

  @action
  public setFilterTextbox(e) {
    this.filterTextBox = e
  }

  protected getRecords() {
    return this.rootStore.recipesStore.currentBoardRecords
  }

  @observable public menuShown: boolean = false
  @observable public event: any
  @observable public filterShown: boolean = false
  @observable public offlineToastShown: boolean = false

  @computed
  public get recipeBotsScreenEnabled(): boolean {
    return this.rootStore.featuresStore.hasRecipeBotsScreenFeature
  }

  @computed
  public get viewMode(): 'list' | 'grid' {
    if (this.rootStore.featuresStore.hasCookbookGridFeature) return 'grid'
    return 'list'
  }

  @action
  public async refresh() {
    if (window.Offline.state === 'down') {
      runInAction(() => (this.offlineToastShown = true))
      return
    }
    this.rootStore.setLoadingProgress(50)
    const proms = [this.rootStore.recipeCategoriesStore.loadData(), this.rootStore.recipesStore.loadData()]
    await Promise.all(proms)
    setTimeout(() => this.rootStore.setLoadingProgress(100), 300)
  }

  @action
  public hideOfflineToast() {
    this.offlineToastShown = false
  }

  @override
  public setFilter(val) {
    this.startCollapsed = !Boolean(val)
    this.group1CollapsedNames = []
    super.setFilter(val)
  }

  @override
  public clearFilter() {
    this.startCollapsed = true
    this.group1CollapsedNames = []
    super.clearFilter()
  }

  public goToAddNew() {
    const url = '/recipeedit/new/?name='
    setTimeout(() => this.rootStore.appStore.navigateTo(url), 300)
  }

  @computed
  public get hasZeroRecipes(): boolean {
    return this.rootStore.recipesStore.currentBoardRecords.length === 0
  }

  public goToSetup(): void {
    this.rootStore.appStore.history.push('/recipecategorieslist')
  }

  @action
  public openMenu(e: React.MouseEvent) {
    e.persist()
    this.event = e
    this.menuShown = true
  }

  @action
  public async sync() {
    await this.refresh()
    this.hideMenu()
  }

  @action
  public hideMenu() {
    this.menuShown = false
  }

  @computed
  public get ptrEnabled(): boolean {
    return this.atTop && !this.sliding && !this.rootStore.loading
  }
}
