import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonFabButton,
  IonFab,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import ListItemsListPageMoreMenu from './ListItemsListPageMoreMenu'
import { add, mic } from 'ionicons/icons'
import { ListItemsStore } from 'src/list-items/store/ListItemsStore'
import ListItemsListPageContent from './ListItemsListPageContent'
import { MaterialCommunityIcons } from 'react-web-vector-icons'
import ProgressLine from '../../../shared/progress-line/ProgressLine'

interface Props {
  listItemsStore: ListItemsStore
  appStore: AppStore
  history: any
}

@inject('listItemsStore')
@inject('appStore')
@observer
export default class ListItemsListPage extends React.Component<Props, any> {
  componentDidMount() {
    this.props.appStore.setCurrentRoute('/')
  }

  renderMicButton() {
    const listVM = this.props.listItemsStore.listVM
    if (!listVM) return null
    if (!listVM.hasVoiceModeFeatureEnabled) return null
    return (
      <IonFabButton
        size='small'
        color='tertiary'
        onMouseDown={() => listVM.startAudioCapture()}
        onMouseUp={() => listVM.stopAudioCapture()}
        onTouchStart={() => listVM.startAudioCapture()}
        onTouchEnd={() => listVM.stopAudioCapture()}
        style={{ marginTop: this.props.appStore.fabOffset }}
      >
        <IonIcon icon={mic} />
      </IonFabButton>
    )
  }

  renderSweepIcon(pos: 'left' | 'right' = 'right') {
    const vm = this.props.listItemsStore.listVM
    if (!vm) return null
    if (pos === 'right' && vm.isIos) return null
    if (pos === 'left' && !vm.isIos) return
    if (!vm.hasCompletedListItems) return
    return (
      <IonButton onClick={(e) => vm.showClearCompletedConfirm()} color='none' size='small'>
        <MaterialCommunityIcons name='broom' size={16} />
      </IonButton>
    )
  }

  render() {
    const listVM = this.props.listItemsStore.listVM
    if (!listVM) return null
    return (
      <>
        <IonHeader>
          <IonToolbar />
        </IonHeader>

        <IonHeader id='ListItemsListPage_Header'>
          <IonToolbar color='secondary'>
            {this.renderSweepIcon('left')}
            <IonTitle>Shopping List</IonTitle>
            <IonButtons slot='end'>
              <ListItemsListPageMoreMenu />
            </IonButtons>
          </IonToolbar>
          <ProgressLine progress={listVM.loadProgress} hidden={listVM.progressHidden} />
        </IonHeader>

        <IonContent
          id='ListItemsListPage_Content'
          style={{ height: this.props.appStore.listHeightPx }}
          ref={(e) => this.props.appStore.applyScrollStyles(e)}
          scrollY
        >
          <ListItemsListPageContent />
        </IonContent>

        <IonFab vertical='bottom' horizontal='end'>
          {!this.props.appStore.isSplitPaneVisible && (
            <IonFabButton
              disabled={listVM.isSeeding}
              color='tertiary'
              onClick={() => listVM.goToItemsSelect()}
              style={{ marginTop: this.props.appStore.fabOffset }}
            >
              <IonIcon icon={add} />
            </IonFabButton>
          )}
          {this.renderMicButton()}
          {this.props.appStore.isSplitPaneVisible && (
            <IonFabButton
              color='tertiary'
              onClick={() => listVM.goToItemsSelect()}
              style={{ marginTop: this.props.appStore.fabOffset }}
            >
              <IonIcon icon={add} />
            </IonFabButton>
          )}
        </IonFab>
      </>
    )
  }
}
