import React from 'react'
import {
  IonContent,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFab,
  IonFabButton,
  IonButtons,
  IonFabList,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { add, eggOutline, linkOutline } from 'ionicons/icons'
import { RootStore } from 'src/stores/RootStore'
import { RecipesStore } from '../../store/RecipesStore'
import { AntDesign } from 'react-web-vector-icons'
import RecipesListPageMoreMenu from './RecipesListPageMoreMenu'
import RecipesListPageContent from './RecipesListPageContent'
import ProgressLine from '../../../shared/progress-line/ProgressLine'

interface Props {
  recipesStore: RecipesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

@inject('recipesStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class RecipesListPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/recipeslist')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  renderSearchbar() {
    const listVM = this.props.recipesStore.listVM
    if (!listVM.filterShown) return
    return (
      <IonToolbar id='RecipesListPage_Toolbar' color='secondary'>
        <input
          type='text'
          className='ion-input-md'
          placeholder='Search...'
          value={listVM.typedFilter}
          onChange={(e: any) => listVM.setFilter(e.target.value)}
          ref={(e) => listVM.setFilterTextbox(e)}
        />

        {listVM.hasFilter && (
          <div slot='end' onClick={() => listVM.clearFilter()}>
            <AntDesign name='closecircleo' size={18} />
          </div>
        )}
      </IonToolbar>
    )
  }

  renderFab() {
    const listVM = this.props.recipesStore.listVM
    if (!listVM.recipeBotsScreenEnabled) return null
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          disabled={listVM.isSeeding}
          color='tertiary'
          className='recipe-fab-button'
          onClick={() => this.props.recipesStore.listVM.goToAddNew()}
          style={{ marginTop: this.props.appStore.fabOffset }}
        >
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
    )
  }

  renderFabList() {
    const listVM = this.props.recipesStore.listVM
    if (listVM.recipeBotsScreenEnabled) return null
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          disabled={listVM.isSeeding}
          color='tertiary'
          className='recipe-fab-button'
          style={{ marginTop: this.props.appStore.fabOffset }}
        >
          <IonIcon icon={add} />
        </IonFabButton>
        <IonFabList id='RecipesListPage_FabList' side='top'>
          <IonFabButton color='tertiary' className='small' onClick={() => listVM.openImport()}>
            <IonIcon icon={linkOutline} />
            &nbsp; Import from URL
          </IonFabButton>

          <IonFabButton color='tertiary' className='smaller' onClick={() => listVM.goToAddNew()}>
            <IonIcon icon={eggOutline} />
            &nbsp; Blank
          </IonFabButton>
        </IonFabList>
      </IonFab>
    )
  }

  render() {
    const { listVM } = this.props.recipesStore
    if (!listVM) return null

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='RecipesListPage_Header'>
          <IonToolbar color='secondary'>
            <IonTitle>Cookbook</IonTitle>
            <IonButtons slot='end'>
              <RecipesListPageMoreMenu />
            </IonButtons>
          </IonToolbar>
          <ProgressLine progress={listVM.loadProgress} hidden={listVM.progressHidden} />
        </IonHeader>
        {this.renderSearchbar()}
        <IonContent
          id='RecipesListPage_Content'
          style={{ height: listVM.finalListHeightPx }}
          scrollY
          ref={(e) => this.props.appStore.applyScrollStyles(e)}
        >
          <RecipesListPageContent />
        </IonContent>
        {this.renderFab()}
        {this.renderFabList()}
      </>
    )
  }
}
