import React from 'react'
import { inject, observer } from 'mobx-react'
import { ListItemsStore } from '../../store/ListItemsStore'
import { AppStore } from '../../../stores/AppStore'
import { IonRefresher, IonRefresherContent } from '@ionic/react'

interface Props {
  listItemsStore?: ListItemsStore
  appStore?: AppStore
}

const ListItemsListPTR: React.FC<Props> = ({ listItemsStore, appStore }) => {
  const { listVM } = listItemsStore
  if (!listVM) return null

  return (
    <IonRefresher
      slot='fixed'
      onIonRefresh={(e) => listVM.refresh(e)}
      style={{ zIndex: 2 }}
      disabled={!listVM.ptrEnabled}
    >
      <IonRefresherContent />
    </IonRefresher>
  )
}

export default inject('listItemsStore', 'appStore')(observer(ListItemsListPTR))
