import React from 'react'
import { AppStore } from '../../../stores/AppStore'
import { inject, observer } from 'mobx-react'
import { IonRefresher, IonRefresherContent } from '@ionic/react'
import SpinnerV2 from '../../../shared/spinner-v2/SpinnerV2'
import { RecipesStore } from '../../store/RecipesStore'
import RecipesList from './RecipesList'
import RecipeCategoriesList from './RecipeCategoriesList'

interface Props {
  appStore?: AppStore
  recipesStore?: RecipesStore
}

const RecipesListPageContent: React.FC<Props> = ({ recipesStore, appStore }) => {
  const { listVM } = recipesStore

  const handleRefresh = (event) => {
    listVM.refresh().then(() => {
      setTimeout(() => {
        if (event) event.target.complete()
      }, 1000)
    })
  }

  const renderNoRecipesMessage = () => {
    if (!listVM) return null
    if (!listVM.hasZeroRecipes) return null
    if (listVM.isSeeding) return null
    return (
      <div className='no-items'>
        Your cookbook is empty.
        <br />
        Start adding your favorite recipes...
      </div>
    )
  }

  const renderNoRecipesFoundMessage = () => {
    if (!listVM) return null
    if (listVM.hasRows) return null
    if (listVM.hasZeroRecipes) return null
    if (listVM.isSeeding) return null
    return <div className='no-items'>No recipes found.</div>
  }

  const renderRefresher = () => {
    return (
      <IonRefresher
        style={{ zIndex: 2 }}
        slot='fixed'
        onIonRefresh={(e) => handleRefresh(e)}
        disabled={!listVM.ptrEnabled}
      >
        <IonRefresherContent />
      </IonRefresher>
    )
  }

  const renderSeedingMessage = () => {
    if (!listVM.isSeeding) return null
    return (
      <>
        <SpinnerV2 type='spinner' isVisible={listVM.isSeeding} />
        <div className='no-items'>
          <br />
          <br />
          <strong>Board initializing, please wait...</strong>
          <br />
          <br />
          This will only take a moment.
        </div>
      </>
    )
  }
  const renderRecipesList = () => {
    if (!listVM.hasRows) return null
    if (listVM.viewMode !== 'list') return null
    return <RecipesList />
  }

  const renderRecipesGrid = () => {
    if (!listVM.hasRows) return null
    if (listVM.viewMode !== 'grid') return null
    return <RecipeCategoriesList />
  }

  return (
    <>
      {renderSeedingMessage()}
      {renderRefresher()}
      {renderRecipesList()}
      {renderRecipesGrid()}
      {renderNoRecipesMessage()}
      {renderNoRecipesFoundMessage()}
    </>
  )
}

export default inject('appStore', 'recipesStore')(observer(RecipesListPageContent))
