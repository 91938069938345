import { RootStore } from 'src/stores/RootStore'
import { computed, action, makeObservable } from 'mobx'
import { Meal } from '../../aggregate/Meal'

export class MealChipVM {
  private rootStore: RootStore
  private meal: Meal

  constructor(rootStore: RootStore, meal: Meal) {
    this.rootStore = rootStore
    this.meal = meal
    makeObservable(this)
  }

  public type: string = 'meal'

  @computed
  public get description(): string {
    return this.meal.Description
    // if (!this.rootStore.recipesStore) return ''
    // const foundRecipe = this.rootStore.recipesStore.getRecipe(this.meal.RecipeGuid)
    // if (foundRecipe) return foundRecipe.Name
    // return ''
  }

  @computed
  public get mealCategoryName(): string {
    if (!this.rootStore.mealCategoriesStore) return ''
    const foundCat = this.rootStore.mealCategoriesStore.get(this.meal.MealCategoryGuid)
    if (foundCat) return foundCat.Name
    return ''
  }

  @computed
  public get mealCategoryColor(): string {
    if (!this.rootStore.mealCategoriesStore) return ''
    const foundCat = this.rootStore.mealCategoriesStore.get(this.meal.MealCategoryGuid)
    if (foundCat) return foundCat.Color
    return ''
  }

  @computed
  public get rank(): number {
    if (!this.rootStore.mealCategoriesStore) return 0
    const foundCat = this.rootStore.mealCategoriesStore.get(this.meal.MealCategoryGuid)
    if (foundCat) return foundCat.Rank
    return 0
  }

  @computed
  public get mealCategoryTime(): string {
    return this.meal.PlannedTime12Hr
  }

  @computed
  public get mealGuid(): string {
    return this.meal.MealGuid
  }

  @computed
  public get key(): string {
    return this.meal.MealGuid
  }

  @action
  public openMeal() {
    let recipeGuid = ''
    if (this.rootStore.appStore.activeModalId === 'MealsListModal') {
      recipeGuid = this.rootStore.recipesStore.editVM?.recipeGuid
    }
    if (!recipeGuid) recipeGuid = ''
    if (recipeGuid) {
      let otherParts = '/' + this.meal.PlannedDateFormatted
      otherParts = otherParts + '/' + this.meal.MealCategoryGuid
      otherParts = otherParts + '/' + recipeGuid
      this.rootStore.appStore.navigateTo('/mealedit/' + this.meal.MealGuid + otherParts)
      return
    }
    this.rootStore.appStore.navigateTo('/mealedit/' + this.meal.MealGuid)
  }

  @computed
  public get mealCategoryGuid(): string {
    return this.meal.MealCategoryGuid
  }
}
