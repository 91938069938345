import { observable, makeObservable, computed, action } from 'mobx'
import { serializable, object, list, serialize } from 'serializr'
import { IItemDTO } from '../dtos/IItemDTO'
import { Category } from './Category'
import { Attachment } from './Attachment'
import { ICategoryDTO } from '../../categories/dtos/ICategoryDTO'

export class Item implements IItemDTO {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public ItemGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public Notes: string = ''
  @serializable @observable public CategoryGuid: string = ''
  @serializable(object(Category)) @observable public Category: Category = undefined
  @serializable(object(Attachment)) @observable public Attachment: Attachment = undefined

  @action
  public clearCategory() {
    this.Category = Category.default()
  }

  @computed
  public get hasNotes(): boolean {
    return this.Notes && this.Notes !== ''
  }

  @computed
  public get hasImage(): boolean {
    return Boolean(this.Attachment)
  }

  public setCategoryFromDto(dto: ICategoryDTO): void {
    this.Category = serialize(Category, dto)
  }
}
