import React from 'react'
import { AppStore } from '../../../stores/AppStore'
import { inject, observer } from 'mobx-react'
import { IonButton, IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import { MealCategoriesStore } from '../../store/MealCategoriesStore'
import MealCategoriesList from './MealCategoriesList'

interface Props {
  appStore?: AppStore
  mealCategoriesStore?: MealCategoriesStore
}

const MealCategoriesListPageContent: React.FC<Props> = ({ mealCategoriesStore, appStore }) => {
  const { listVM } = mealCategoriesStore

  const renderNoCategoriesMessage = () => {
    if (!listVM) return null
    if (listVM.rows.length > 0) return null

    return (
      <div className='no-items'>
        You don't have any meal slots set up.
        <br />
        Add some meal slots (breakfast, lunch, etc)...
        <br />
        <br />
        <IonButton slot='icon-only' color='tertiary' type='button' onClick={() => listVM.goToAddNew()}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    )
  }

  return (
    <>
      {renderNoCategoriesMessage()}
      <MealCategoriesList />
    </>
  )
}

export default inject('appStore', 'mealCategoriesStore')(observer(MealCategoriesListPageContent))
