import React from 'react'
import './ProgressLine.scss'
import { observer } from 'mobx-react'

interface Props {
  progress: number
  hidden?: boolean
}

const ProgressLine: React.FC<Props> = ({ progress, hidden }) => {
  if (hidden) return null
  return (
    <div className='progress-line'>
      <div className='progress-line__line' style={{ width: `${progress}%` }} />
    </div>
  )
}

export default observer(ProgressLine)
