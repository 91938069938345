import superagentPromise from 'superagent-promise'
import _superagent from 'superagent'

const superagent = superagentPromise(_superagent, global.Promise)
const API_ROOT = process.env.REACT_APP_API_URL + '/api'
const handleErrors = (err) => err
const responseBody = (res) => res.body

const requests = {
  get: (url, token) => {
    const finalUrl = `${API_ROOT}${url}`
    return superagent
      .get(finalUrl)
      .use((req) => {
        if (token) req.set('Authorization', `Bearer ${token}`)
      })
      .end(handleErrors)
      .then(responseBody)
  },

  post: (url, body = {}, token = null) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use((req) => {
        let tk = null
        if (token) tk = token
        else if (window && window.token) tk = window.token
        req.set('Authorization', `Bearer ${tk}`)
      })
      .end(handleErrors)
      .then(responseBody),
}

const Items = {
  getAll: (token) => requests.get('/items/all', token),
  save: (form) => requests.post('/items/save', { ...form }),
}

const Recipes = {
  getAll: (token) => requests.get('/recipes/all', token),
  save: (form) => requests.post('/recipes/save', { ...form }),
}

const MealCategories = {
  getAll: (token) => requests.get('/mealcategories/all', token),
  save: (form) => requests.post('/mealcategories/save', { ...form }),
}

const RecipeCategories = {
  getAll: (token) => requests.get('/recipecategories/all', token),
  save: (form) => requests.post('/recipecategories/save', { ...form }),
}

const Meals = {
  getAll: (token) => requests.get('/meals/all', token),
  save: (form) => requests.post('/meals/save', { ...form }),
  delete: (form) => requests.post('/meals/delete', { ...form }),
}

const ListItems = {
  getAll: (token) => requests.get('/listitems/all', token),
  save: (form) => requests.post('/listitems/save', { ...form }),
}

const Categories = {
  getAll: (token) => requests.get('/categories/all', token),
  save: (form) => requests.post('/categories/save', { ...form }),
}

const BotSessions = {
  getAll: (token) => requests.get('/botsessions/all', token),
  get: (form) => requests.post('/botsessions/get', { ...form }),
  save: (form) => requests.post('/botsessions/save', { ...form }),
  delete: (form) => requests.post('/botsessions/delete', { ...form }),
}

const StorageFiles = {
  getAll: (token) => requests.get('/storagefiles/all', token),
  save: (form) => requests.post('/storagefiles/save', { ...form }),
  delete: (form) => requests.post('/storagefiles/delete', { ...form }),
  upload: (form) => requests.post('/storagefiles/uploadfile', { ...form }),
}

const Boards = {
  getAll: (token) => requests.get('/boards/all', token),
  generateInvitationCode: (form) => requests.post('/boards/generateinvitationcode', { ...form }),
  joinBoard: (invitationCode) => requests.post('/boards/join', { invitationCode: invitationCode }),
}

const BoardInvitations = {
  getAll: (token) => requests.get('/boardinvitations/all', token),
  save: (form) => requests.post('/boardinvitations/save', { ...form }),
}

const AdaptiveCardTemplates = {
  getAll: () => requests.get('/../assets/adaptivecardtemplates/all.json?dt=' + new Date(), ''),
  get: (id) => requests.get('/../assets/adaptivecardtemplates/' + id + '.json?dt=' + new Date(), ''),
}

export default {
  AdaptiveCardTemplates,
  Recipes,
  MealCategories,
  RecipeCategories,
  Items,
  Meals,
  ListItems,
  Categories,
  Boards,
  BotSessions,
  BoardInvitations,
  StorageFiles,
}
