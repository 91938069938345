import { MealsStore } from './../meals/store/MealsStore'
import { MealCategoriesStore } from './../meal-categories/store/MealCategoriesStore'
import { ListItemsStore } from '../list-items/store/ListItemsStore'
import { ItemsStore } from '../items/store/ItemsStore'
import { AppStore } from './AppStore'
import { AuthStore } from '../login/store/AuthStore'
import { ActionsStore } from '../actions/store/ActionsStore'
import { CategoriesStore } from '../categories/store/CategoriesStore'
import { BoardsStore } from '../boards/store/BoardsStore'
import { NotificationsStore } from '../notifications/NotificationsStore'
import { RecipesStore } from '../recipes/store/RecipesStore'
import { RecipeCategoriesStore } from '../recipe-categories/store/RecipeCategoriesStore'
import { IItemsStore } from 'src/items/store/IItemsStore'
import { BoardInvitationsStore } from '../board-invitations/store/BoardInvitationsStore'
import * as Sentry from '@sentry/browser'
import { delay } from '../shared/delay'
import { RootStore as ElexiAppRootStore } from '@elexient/elexiapp.bits.shared'
import { UserStore } from '../user/store/UserStore'
import { BotSessionsStore } from '../bot-sessions/store/BotSessionsStore'
import { StorageFilesStore } from '../storage-files/store/StorageFilesStore'
import { AdaptiveCardTemplatesStore } from '../adaptive-cards/store/AdaptiveCardTemplatesStore'
import { FeaturesStore } from '../features/store/FeaturesStore'
import { action, makeObservable, observable, runInAction } from 'mobx'

export class RootStore extends ElexiAppRootStore {
  constructor() {
    super()
    makeObservable(this)
    this.authStore = this.addStore<AuthStore>(AuthStore)
    this.boardsStore = this.addStore<BoardsStore>(BoardsStore)
    this.appStore = this.addStore<AppStore>(AppStore)
    this.itemsStore = this.addStore<ItemsStore>(ItemsStore)
    this.actionsStore = this.addStore<ActionsStore>(ActionsStore)
    this.listItemsStore = this.addStore<ListItemsStore>(ListItemsStore)
    this.categoriesStore = this.addStore<CategoriesStore>(CategoriesStore)
    this.botSessionsStore = this.addStore<BotSessionsStore>(BotSessionsStore)
    this.userStore = this.addStore<UserStore>(UserStore)
    this.boardInvitationsStore = this.addStore<BoardInvitationsStore>(BoardInvitationsStore)
    this.notificationsStore = this.addStore<NotificationsStore>(NotificationsStore)
    this.recipesStore = this.addStore<RecipesStore>(RecipesStore)
    this.recipeCategoriesStore = this.addStore<RecipeCategoriesStore>(RecipeCategoriesStore)
    this.mealCategoriesStore = this.addStore<MealCategoriesStore>(MealCategoriesStore)
    this.mealsStore = this.addStore<MealsStore>(MealsStore)
    this.storageFilesStore = this.addStore<StorageFilesStore>(StorageFilesStore)
    this.adaptivCardTemplatesStore = this.addStore<AdaptiveCardTemplatesStore>(AdaptiveCardTemplatesStore)
    this.featuresStore = this.addStore<FeaturesStore>(FeaturesStore)
    this.registerInternetRestoredHandler()
  }

  public itemsStore: IItemsStore
  public listItemsStore: ListItemsStore
  public appStore: AppStore
  public authStore: AuthStore
  public actionsStore: ActionsStore
  public categoriesStore: CategoriesStore
  public boardsStore: BoardsStore
  public userStore: UserStore
  public boardInvitationsStore: BoardInvitationsStore
  public notificationsStore: NotificationsStore
  public recipesStore: RecipesStore
  public recipeCategoriesStore: RecipeCategoriesStore
  public mealCategoriesStore: MealCategoriesStore
  public mealsStore: MealsStore
  public botSessionsStore: BotSessionsStore
  public storageFilesStore: StorageFilesStore
  public adaptivCardTemplatesStore: AdaptiveCardTemplatesStore
  public featuresStore: FeaturesStore

  private delayMS: number = 0
  private loadingStartedTime: number
  private appBackgroundedDateTime: number
  private SECONDS_BETWEEN_LOADS = 40
  private blocksToLoad: number = 0
  private blocksLoaded: number = 0

  @observable public loading: boolean = false
  @observable public loadProgress: number = 0
  @observable public progressHidden: boolean = false

  private registerInternetRestoredHandler() {
    window.Offline.on('up', () => {
      this.loadData('internet restored')
    })
  }

  public handleAppBackgrounded() {
    this.appBackgroundedDateTime = Date.now()
  }

  public loadData(reason: string, attempts: number = 1) {
    let DEBUG = false
    let fastLoad = false
    if (process.env.REACT_APP_IS_DEV_MODE !== '1') DEBUG = false
    if (this.loading && reason !== 'user logged in') {
      console.log('SKIPPING ' + reason + ': loading already')
      return
    }
    if (window.Offline.state === 'down') {
      console.log('SKIPPING ' + reason + ': OFFLINE')
      return
    }
    if (reason === 'app restored') {
      const timeSinceLastLoad = Date.now() - this.appBackgroundedDateTime
      if (timeSinceLastLoad < this.SECONDS_BETWEEN_LOADS * 1000) {
        console.log('SKIPPING ' + reason + ': not enough time since last load')
        return
      }
      // fastLoad = true
    }
    if (DEBUG) console.log('LOADING data: ' + reason + ' attempt: ' + attempts)
    attempts++
    if (attempts === 7) return delay(1)
    if (!this.appStore.isLoggedIn) {
      return delay(2000).then(() => this.loadData(reason, attempts))
    }
    if (process.env.NODE_ENV === 'test') {
      const proms = [this.boardsStore.loadData(), this.itemsStore.loadData(), this.listItemsStore.loadData()]
      return Promise.all(proms)
    }
    const proms = [
      delay(this.getMoreTime(1000)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'boards'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.boardsStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'user'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.userStore
          .loadUser()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'categories'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.categoriesStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'items'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.itemsStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'listitems'
        if (DEBUG) console.time(name)
        return this.listItemsStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'recipecategories'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.recipeCategoriesStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'recipes'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.recipesStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'mealcategories'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.mealCategoriesStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'meals'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.mealsStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'boardinvitations'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.boardInvitationsStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'botsessions'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.botSessionsStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'storagefiles'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.storageFilesStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        const name = 'notifications'
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.notificationsStore
          .processNotifications()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
      delay(this.getMoreTime(200)).then(() => {
        const name = 'adaptiveCardTemplates'
        if (fastLoad) {
          this.reportBlockLoaded()
          return
        }
        if (DEBUG) console.time(name)
        this.appStore.setLoadingData(name)
        return this.adaptivCardTemplatesStore
          .loadData()
          .then(() => DEBUG && console.timeEnd(name))
          .finally(() => this.reportBlockLoaded())
      }),
    ]
    runInAction(() => (this.loading = true))
    this.blocksLoaded = 0
    this.loadingStartedTime = Date.now()
    this.setLoadingProgress(1)
    this.blocksToLoad = proms.length
    return delay(1000)
      .then(() => Promise.all(proms).then(() => runInAction(() => (this.loading = false))))
      .catch((e) => {
        runInAction(() => (this.loading = false))
        Sentry.captureException(e)
        console.error(e)
      })
      .finally(() => this.setLoadingProgress(100))
  }

  @action
  private reportBlockLoaded() {
    this.blocksLoaded++
    const progress = this.blocksLoaded / this.blocksToLoad
    this.setLoadingProgress(progress * 100)
  }

  @action
  public setLoadingProgress(progress: number) {
    if (progress >= 100) {
      setTimeout(
        () =>
          runInAction(() => {
            this.progressHidden = true
            this.loadProgress = 0
          }),
        1000
      )
      setTimeout(() => runInAction(() => (this.progressHidden = false)), 1500)
      progress = 100
    }
    this.loadProgress = progress
  }

  private getMoreTime(ms) {
    this.delayMS = this.delayMS + ms / 3
    return 10
    // return this.delayMS
  }

  public processActions() {
    setInterval(() => this.actionsStore.actionsSvc.processActions(), 8000)
  }
}
