import React from 'react'
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { RootStore } from 'src/stores/RootStore'
import MealsList from './MealsList'
import { MealsStore } from '../../store/MealsStore'
import { EvilIcons, MaterialCommunityIcons } from 'react-web-vector-icons'
import MealsListPageMoreMenu from './MealsListPageMoreMenu'
import MealsListPageContent from './MealsListPageContent'
import ProgressLine from '../../../shared/progress-line/ProgressLine'

interface Props {
  mealsStore: MealsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

@inject('mealsStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class MealsListPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/mealslist')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  render() {
    const { listVM } = this.props.mealsStore
    if (!listVM) return null

    const renderTodayButton = (pos) => {
      if (pos === 'right' && listVM.isIos) return
      if (pos === 'left' && !listVM.isIos) return
      return (
        <IonButton onClick={() => listVM.goToToday()} disabled={listVM.isSeeding}>
          <MaterialCommunityIcons name='calendar-today' size={20} />
        </IonButton>
      )
    }

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='MealsListPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>{renderTodayButton('left')}</IonButtons>
            <IonTitle>Planner</IonTitle>
            <IonButtons slot='end'>
              {renderTodayButton('right')}
              <IonButton onClick={() => listVM.decreaseStartDate()} disabled={listVM.isSeeding}>
                <EvilIcons name='chevron-left' size={20} />
              </IonButton>
              <IonButton onClick={() => listVM.inccreaseStartDate()} disabled={listVM.isSeeding}>
                <EvilIcons name='chevron-right' size={20} />
              </IonButton>
              <MealsListPageMoreMenu />
            </IonButtons>
          </IonToolbar>
          <ProgressLine progress={listVM.loadProgress} hidden={listVM.progressHidden} />
        </IonHeader>

        <IonContent
          id='MealsListPage_Content'
          ref={(e) => this.props.appStore.applyScrollStyles(e)}
          style={{ height: this.props.appStore.listHeightPx }}
          scrollY
        >
          <MealsListPageContent />
        </IonContent>
      </>
    )
  }
}
