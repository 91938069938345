import { Recipe } from '../../../recipes/aggregate/Recipe'
import { RootStore } from 'src/stores/RootStore'
import { MealCategory } from '../../../meal-categories/aggregate/MealCategory'
import { computed, observable, action, makeObservable, runInAction } from 'mobx'
import { MealsService } from '../../services/MealsService'
import { IMealDTO } from '../../dtos/IMealDTO'
import { Meal } from '../../aggregate/Meal'
import { MealRecipeCardVM } from './MealRecipeCardVM'
import { MealItemVM } from './MealItemVM'
import { RecipeSelectListVM } from './RecipeSelectListVM'
import { ItemsSelectListVM } from '../../../items-select/view-models/ItemsSelectListVM'
import { IItemSelectableVM } from '../../../items-select/interfaces/IItemSelectableVM'
import moment from 'moment'
import { Day } from '../../../meal-categories/aggregate/Day'

export class MealEditVM implements IItemSelectableVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, meal: Meal, recipeGuid: string = '') {
    makeObservable(this)
    this.rootStore = rootStore
    this.meal = meal
    this.recipeSelectList = new RecipeSelectListVM(this.rootStore, this)
    this.itemsList = new ItemsSelectListVM(this.rootStore, this, false, false)
    if (recipeGuid) {
      this.addRecipe(recipeGuid)
      this.save(false)
    }
  }

  @observable public meal: Meal = null
  @observable public deleteConfirmShown: boolean = false
  @observable public currentTab: string = 'recipes'
  @observable public itemActionSheetShown: boolean = false
  @observable public addRecipeModalShown: boolean = false
  @observable public recipeSelectList: RecipeSelectListVM = null
  @observable public recipeMenuShown: boolean
  @observable public currentRecipeGuid: string = ''
  @observable public itemsList: ItemsSelectListVM = null
  public recipeMenuEvent: Event
  public type: string = 'Meal'

  @computed
  public get isNewMeal(): boolean {
    return this.meal.IsNew
  }

  @computed
  public get mealCategoryGuid(): string {
    if (!this.meal) return ''
    return this.meal.MealCategoryGuid
  }

  @computed
  private get mealCategory(): MealCategory {
    return this.rootStore.mealCategoriesStore.get(this.mealCategoryGuid)
  }

  @computed
  private get mealCategoryDay(): Day {
    if (!this.mealCategory) return undefined
    return this.mealCategory.Days.find((e) => e.DayIndex === this.meal.PlannedDateDayIndex)
  }

  @computed
  private get mealCategoryHasDefaultTime(): boolean {
    if (!this.mealCategory) return undefined
    return this.mealCategory.SpecificTime
  }

  @action
  public addRecipe(recipeGuid: string) {
    const existingRecipe = this.mealRecipes.find((e) => e.recipeGuid === recipeGuid)
    if (existingRecipe) return
    const hasCustomDescription = this.description !== this.computedDescription
    this.meal.addRecipe(recipeGuid)
    // const recipe = this.rootStore.recipesStore.get(recipeGuid)
    // recipe.RecipeItems.forEach(e => this.meal.addItem(e.ItemGuid, recipeGuid))
    if (!hasCustomDescription) this.setDescription(this.computedDescription)
  }

  @computed
  public get recipes(): Recipe[] {
    if (!this.rootStore.recipesStore) return []
    return this.rootStore.recipesStore.currentBoardRecords
  }

  @action
  public toggleSpecificTime(force: boolean = true) {
    if (this.mealCategoryDay && this.mealCategoryDay.SpecificTime) {
      this.meal.toggleSpecificTime(this.mealCategoryDay.Time)
      return
    }
    if (this.mealCategoryHasDefaultTime) {
      this.meal.toggleSpecificTime(this.mealCategory.Time)
      return
    }
    if (!force) return
    this.meal.toggleSpecificTime()
  }

  @action
  public disableSpecificTime() {
    this.meal.disableSpecificTime()
  }

  @computed
  public get specificTime(): boolean {
    return this.meal.SpecificTime
  }

  @computed
  public get localPlannedDateTime(): moment.Moment {
    return this.meal.localPlannedDateTime
  }

  @action
  public setPlannedDateTime(val) {
    const clonedVal = val.clone()
    if (!clonedVal.isSame(this.meal.localPlannedDateTime, 'day')) {
      this.meal.setPlannedDateTime(val)
      this.disableSpecificTime()
      this.toggleSpecificTime(false)
      return
    }
    this.meal.setPlannedDateTime(val)
  }

  @computed
  public get description(): string {
    if (!this.meal) return ''
    return this.meal.Description
  }

  @action
  public setDescription(val) {
    this.meal.setDescription(val)
  }

  @computed
  private get computedDescription() {
    let text = ''
    this.mealRecipes
      .sort((a, b) => a.rank - b.rank)
      .forEach((e) => {
        if (text !== '') text += ', '
        text += e.name
      })
    this.otherItems
      .sort((a, b) => a.rank - b.rank)
      .forEach((e) => {
        if (text !== '') text += ', '
        text += e.name
      })
    return text
  }

  @computed
  public get mealRecipes(): Array<MealRecipeCardVM> {
    if (!this.meal) return []
    return this.meal.MealRecipes.filter((e) => !e.IsDeleted).map((e) => new MealRecipeCardVM(this.rootStore, this, e))
  }

  @computed
  public get mealItems(): Array<MealItemVM> {
    if (!this.meal) return []
    return this.meal.MealItems.filter((e) => !e.IsDeleted && !e.RecipeGuid).map(
      (e) => new MealItemVM(this.rootStore, this, e)
    )
  }

  public getItemQuantity(itemGuid: string) {
    return this.getMealItem(itemGuid) ? 1 : 0
  }

  public getIsGotten(): boolean {
    return false
  }

  public toggleGotten() {}
  public markAsCleared() {}

  @action
  public increaseQuantity(itemGuid: string, qty: number = 0) {
    const hasCustomDescription = this.description !== this.computedDescription
    this.meal.addItem(itemGuid, null, qty)
    if (!hasCustomDescription) this.setDescription(this.computedDescription)
  }

  @computed
  public get hasOtherItems(): boolean {
    return this.otherItems.length !== 0
  }

  @computed
  public get otherItems(): Array<MealItemVM> {
    return this.mealItems.filter((e) => e.recipeGuid === null).sort((a, b) => (a.sortName < b.sortName ? -1 : 1))
  }

  @action
  public decreaseQuantity(itemGuid: string) {}

  @action
  public removeQuantity(itemGuid: string) {
    const foundItem = this.mealItems.find((e) => e.itemGuid === itemGuid && !e.recipeGuid)
    if (foundItem && !foundItem.recipeGuid) this.meal.deleteItem(foundItem.mealItemGuid)
  }

  public doneAddingItems() {}

  @action
  public deleteRecipe() {
    this.hideRecipeMenu()
    const hasCustomDescription = this.description !== this.computedDescription
    this.meal.deleteRecipe(this.currentRecipeGuid)
    if (!hasCustomDescription) this.setDescription(this.computedDescription)
  }

  @action
  public async save(doRedirect: boolean = true) {
    const dto: IMealDTO = this.meal.toDTO()
    const svc = new MealsService(this.rootStore)
    await svc.save(dto)
    // this.rootStore.appStore.history.replace('/mealslist')
    if (!doRedirect) return
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get pageTitle(): string {
    return this.isNewMeal ? 'Add Meal' : this.meal ? 'Edit Meal' : ''
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  public async delete() {
    const svc = new MealsService(this.rootStore)
    await svc.delete(this.meal.toDTO())
    this.rootStore.mealsStore.deleteDraftMeal()
    this.rootStore.appStore.handleGoBack()
  }

  @action
  public back() {
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get mealCategories(): MealCategory[] {
    if (!this.rootStore.mealCategoriesStore) return []
    return this.rootStore.mealCategoriesStore.currentBoardRecords.sort((a, b) => (a.Rank < b.Rank ? -1 : 1))
  }

  @action
  public setMealCategory(val: string) {
    this.meal.setMealCategory(val)
    this.disableSpecificTime()
    this.toggleSpecificTime(false)
  }

  @action
  public showItemActionSheet() {
    this.itemActionSheetShown = true
  }

  @action
  public hideItemActionSheet() {
    this.itemActionSheetShown = false
  }

  @action
  public showRecipeMenu(e: any, recipeGuid: string) {
    this.currentRecipeGuid = recipeGuid
    e.persist()
    this.recipeMenuEvent = e
    setTimeout(() => runInAction(() => (this.recipeMenuShown = true)), 1)
  }

  @action
  public hideRecipeMenu() {
    this.recipeMenuShown = false
  }

  @action
  public goToRecipe() {
    this.hideRecipeMenu()
    setTimeout(() => this.rootStore.appStore.history.push('/recipeedit/' + this.currentRecipeGuid), 1)
  }

  public getMealItemsForRecipe(recipeGuid: string): Array<MealItemVM> {
    return this.mealItems.filter((e) => e.recipeGuid === recipeGuid).sort((a, b) => (a.sortName < b.sortName ? -1 : 1))
  }

  public getMealItem(itemGuid): MealItemVM {
    return this.mealItems.find((e) => e.itemGuid === itemGuid)
  }

  @action
  public showAddRecipeModal() {
    this.recipeSelectList.show()
  }

  @action
  public showAddItemModal() {
    this.itemsList.show()
  }

  @computed
  public get isValid(): boolean {
    if (this.description === '') return false
    return true
  }

  reloadRows() {}
}
