import { observable, action, computed, makeObservable } from 'mobx'
import { ListItem } from '../aggregate/ListItem'
import { ListItemsListVM } from '../view-models/ListItemsListVM'
import { IListItemDTO } from '../dtos/IListItemDTO'
import { deserialize } from 'serializr'
import AgentV2 from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { makePersistable } from 'mobx-persist-store'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import * as DataStoreServiceWorker from '../../shared/DataStoreService.worker'

export class ListItemsStore extends DataStore<RootStore, ListItem, IListItemDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, ListItem, AgentV2, 'ListItems', 'ListItem')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'ListItemsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(ListItem, e))))
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.lazyLoadListVM()
  }

  @observable public isHydrated: boolean = false
  @observable.shallow public persistedRecords: ListItem[] = []
  @observable public listVM: ListItemsListVM = null

  private lazyLoadListVM() {
    if (!this.rootStore.categoriesStore.isHydrated) {
      setTimeout(() => this.lazyLoadListVM(), 100)
      return
    }
    if (!this.rootStore.itemsStore.isHydrated) {
      setTimeout(() => this.lazyLoadListVM(), 100)
      return
    }
    this.listVM = new ListItemsListVM(this.rootStore)
  }

  @computed
  public get currentBoardRecords(): Array<ListItem> {
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  public get(listItemGuid: string): ListItem {
    return this.persistedRecords.find((e) => e.ListItemGuid === listItemGuid)
  }

  public getByItemGuid(itemGuid: string): ListItem {
    return this.persistedRecords.find((e) => e.ItemGuid === itemGuid)
  }

  public getUnClearedByItemGuid(itemGuid: string): ListItem {
    return this.persistedRecords.find((e) => e.ItemGuid === itemGuid && !e.isCleared)
  }

  protected getWorker() {
    return (DataStoreServiceWorker as any)() as typeof DataStoreServiceWorker
  }

  public onLoadDataCompleted() {
    this.listVM.reloadRows()
  }
}
