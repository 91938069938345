import { RootStore } from '../../stores/RootStore'
import { computed } from 'mobx'
import { RecipeCategoryRowVM } from './RecipeCategoryRowVM'

export class RecipeCategoriesListVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed
  public get rows(): Array<RecipeCategoryRowVM> {
    return this.rootStore.recipeCategoriesStore.currentBoardRecords
      .sort((a, b) => {
        const aVal = a.Name.toLowerCase()
        const bVal = b.Name.toLowerCase()
        if (aVal < bVal) return -1
        if (aVal > bVal) return 1
        return 0
      })
      .map((e) => new RecipeCategoryRowVM(this.rootStore, e))
  }
}
