import React from 'react'
import { IonButton, IonIcon, IonPopover, IonItem } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { ellipsisVertical, refresh, searchOutline } from 'ionicons/icons'
import { EvilIcons } from 'react-web-vector-icons'
import { RecipesStore } from '../../store/RecipesStore'
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  recipesStore?: RecipesStore
}

const RecipesListPageMoreMenu: React.FC<Props> = ({ recipesStore }) => {
  const vm = recipesStore.listVM

  const renderSearchIcon = () => {
    return (
      <IonButton onClick={(e) => vm.toggleFilter()} disabled={vm.isSeeding}>
        <IonIcon icon={searchOutline} mode='ios' />
      </IonButton>
    )
  }

  const renderExpandAll = () => {
    if (!vm.startCollapsed) return
    return (
      <IonButton onClick={(e) => vm.expandCollapseAll()} disabled={vm.isSeeding}>
        <FontAwesomeIcon icon={faAngleDoubleDown} />
      </IonButton>
    )
  }

  const renderCollapseAll = () => {
    if (vm.startCollapsed) return
    return (
      <IonButton onClick={(e) => vm.collapseAll()} disabled={vm.isSeeding}>
        <FontAwesomeIcon icon={faAngleDoubleUp} />
      </IonButton>
    )
  }

  return (
    <div id='RecipesListPageMoreMenu'>
      {renderSearchIcon()}
      {renderExpandAll()}
      {renderCollapseAll()}
      <IonButton onClick={(e) => vm.openMenu(e)} disabled={vm.isSeeding}>
        <IonIcon icon={ellipsisVertical} mode='ios' />
      </IonButton>

      <IonPopover
        id='RecipesListPageMoreMenu_Content'
        isOpen={vm.menuShown}
        event={vm.event}
        onDidDismiss={() => vm.hideMenu()}
      >
        <IonItem lines='full' onClick={() => vm.goToSetup()}>
          <EvilIcons name='gear' size={18} />
          &nbsp; Recipe Categories
        </IonItem>
        <IonItem lines='full' onClick={() => vm.sync()}>
          <IonIcon icon={refresh} size={'small'} />
          &nbsp; Sync
        </IonItem>
      </IonPopover>
    </div>
  )
}

export default inject('recipesStore')(observer(RecipesListPageMoreMenu))
