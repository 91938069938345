import React from 'react'
import { IonContent, IonIcon, IonButton, IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { add, funnel, checkmark, close, arrowBack, checkmarkSharp, filterOutline } from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import { MealCategoriesStore } from '../../store/MealCategoriesStore'
import MealCategoriesList from './MealCategoriesList'
import BackIcon from 'src/shared/BackIcon'
import MealCategoriesListPageContent from './MealCategoriesListPageContent'

interface Props {
  mealCategoriesStore: MealCategoriesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

@inject('mealCategoriesStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class MealCategoriesListPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/mealcategorieslist')
  }

  render() {
    const { listVM } = this.props.mealCategoriesStore
    if (!listVM) return null

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='MealCategoriesListPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => listVM.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>

            <IonTitle>Meal Slots</IonTitle>
            <IonButtons slot='end'>
              {listVM.sortMode ? (
                <>
                  <IonButton onClick={() => listVM.cancelSort()}>
                    <IonIcon icon={close} />
                  </IonButton>
                  <IonButton onClick={() => listVM.saveSort()}>
                    <IonIcon icon={checkmarkSharp} />
                  </IonButton>
                </>
              ) : (
                <>
                  <IonButton onClick={() => listVM.enterSortMode()}>
                    <IonIcon icon={filterOutline} />
                  </IonButton>
                  <IonButton onClick={() => listVM.goToAddNew()}>
                    <IonIcon icon={add} />
                  </IonButton>
                </>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='MealCategoriesListPage_Content'
          ref={(e) => this.props.appStore.applyScrollStyles(e)}
          style={{ height: this.props.appStore.listHeightPx }}
        >
          <MealCategoriesListPageContent />
        </IonContent>
      </>
    )
  }
}
