import { RecipesStore } from 'src/recipes/store/RecipesStore'
import { RootStore } from 'src/stores/RootStore'
import { computed, observable, action, makeObservable, reaction, override } from 'mobx'
import { RecipeGroupRowVM } from './RecipeGroupRowVM'
import { MealEditVM } from './MealEditVM'
import { RecipeRowVM } from './RecipeRowVM'
import { Capacitor } from '@capacitor/core'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { Recipe } from '../../../recipes/aggregate/Recipe'
import { RecipeNewRowVM } from '../../../recipes/view-models/list/RecipeNewRowVM'

export class RecipeSelectListVM extends ListVM<RootStore, Recipe, RecipeGroupRowVM, RecipeRowVM, RecipeNewRowVM> {
  private editVM: MealEditVM

  constructor(rootStore: RootStore, editVM: MealEditVM) {
    super(rootStore, RecipeRowVM, RecipeGroupRowVM, RecipeNewRowVM)
    this.stickyHeaders = true
    this.autoGrow = true
    this.startCollapsed = true
    this.setGroups('recipeCategoryNames', '')
    this.editVM = editVM
    this.addReaction(
      reaction(
        () => this.rootStore.appStore.listHeightForModal,
        () => this.setHeight(this.rootStore.appStore.listHeightForModal),
        { delay: 200 }
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.recipesStore.recordJustUpdated,
        () => this.reloadRows()
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.boardsStore.currentBoardId,
        () => this.reloadRows()
      )
    )
    this.setHeight(this.rootStore.appStore.listHeightForModal)
  }

  protected aggregateSortFunction = (a: Recipe, b: Recipe): number => {
    const aVal = a.Name.toLowerCase()
    const bVal = b.Name.toLowerCase()
    if (aVal < bVal) return -1
    return 1
  }

  protected getRecords() {
    return this.rootStore.recipesStore.currentBoardRecords
  }

  @observable public shown: boolean = false

  @override
  public setFilter(val) {
    this.startCollapsed = !Boolean(val)
    this.group1CollapsedNames = []
    super.setFilter(val)
  }

  @override
  public clearFilter() {
    this.startCollapsed = true
    this.group1CollapsedNames = []
    super.clearFilter()
  }

  public goToAddNew() {
    setTimeout(() => this.rootStore.appStore.history.push('/recipeedit/new/?name=' + escape(this.filter)), 500)
  }

  @action
  public done(): void {
    this.hide()
  }

  @action
  public cancel(): void {
    this.hide()
  }

  @action
  public hide(): void {
    this.shown = false
  }

  public goBack() {
    if (this.hasFilter) {
      this.clearFilter()
      return
    }
    this.hide()
  }

  @action
  public show(): void {
    this.shown = true
    this.rootStore.appStore.setActiveModal('RecipeSelectModal', 56 * 2)
    window.location.hash = '/recipeslist'
  }

  @action
  public modalShown() {
    this.rootStore.appStore.setActiveModal('RecipeSelectModal', 56 * 2)
  }

  @action
  public addRecipe(recipeGuid) {
    this.editVM.addRecipe(recipeGuid)
  }
}
