import React from 'react'
import { AppStore } from '../../../stores/AppStore'
import { inject, observer } from 'mobx-react'
import { IonButton, IonRefresher, IonRefresherContent } from '@ionic/react'
import { MealsStore } from '../../store/MealsStore'
import MealsList from './MealsList'
import { EvilIcons } from 'react-web-vector-icons'
import SpinnerV2 from '../../../shared/spinner-v2/SpinnerV2'

interface Props {
  appStore?: AppStore
  mealsStore?: MealsStore
}

const MealsListPageContent: React.FC<Props> = ({ mealsStore, appStore }) => {
  const { listVM } = mealsStore

  const handleRefresh = (event) => {
    const { listVM } = mealsStore
    listVM.refresh().then(() => {
      setTimeout(() => {
        if (event) event.target.complete()
      }, 1000)
    })
  }

  const renderRefresher = () => {
    return (
      <IonRefresher slot='fixed' onIonRefresh={(e) => handleRefresh(e)}>
        <IonRefresherContent />
      </IonRefresher>
    )
  }

  const renderSeedingMessage = () => {
    if (!listVM.isSeeding) return null
    return (
      <>
        <SpinnerV2 type='spinner' isVisible={listVM.isSeeding} />
        <div className='no-items'>
          <br />
          <br />
          <strong>Board initializing, please wait...</strong>
          <br />
          <br />
          This will only take a moment.
        </div>
      </>
    )
  }

  const renderNoMeals = () => {
    if (listVM.hasVisibleMeals) return null
    if (listVM.isSeeding) return null
    return (
      <div className='no-items'>
        No meal slots available for planning.
        <br />
        Adjust your meal slots...
        <br />
        <br />
        <IonButton slot='icon-only' color='tertiary' type='button' onClick={() => listVM.goToSetup()}>
          <EvilIcons name='gear' size={20} />
        </IonButton>
      </div>
    )
  }

  const renderMealsList = () => {
    if (listVM.isSeeding) return null
    return <MealsList />
  }

  return (
    <>
      {renderRefresher()}
      {renderNoMeals()}
      {renderSeedingMessage()}
      {renderMealsList()}
    </>
  )
}

export default inject('appStore', 'mealsStore')(observer(MealsListPageContent))
