import { RootStore } from 'src/stores/RootStore'
import { computed } from 'mobx'
import { CategoriesStore } from '../store/CategoriesStore'
import { CategoryRowVM } from './CategoryRowVM'

export class CategoriesListVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed
  private get categoriesStore(): CategoriesStore {
    return this.rootStore.categoriesStore
  }

  @computed
  public get rows(): CategoryRowVM[] {
    return this.categoriesStore.currentBoardRecords
      .filter(e => e.BoardId !== 0)
      .sort((a, b) => {
        const aVal = a.Name.toLowerCase()
        const bVal = b.Name.toLowerCase()
        if (aVal < bVal) return -1
        if (aVal > bVal) return 1
        return 0
      })
      .map(e => new CategoryRowVM(this.rootStore, this, e))
  }
}
