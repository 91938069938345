import React, { useEffect } from 'react'
import { IonModal, IonTitle, IonToolbar, IonHeader, IonButtons, IonButton } from '@ionic/react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { RootStore } from '../../../stores/RootStore'
import BackIcon from '../../../shared/BackIcon'
import { AppStore } from '../../../stores/AppStore'
import { MealsListModalVM } from '../../view-models/list/MealsListModalVM'
import MealsList from './MealsList'

interface Props {
  rootStore?: RootStore
  appStore?: AppStore
  shown: boolean
  title: string
  description: string
  onHide: () => void
}

const MealsListModal: React.FC<Props> = (props) => {
  const { shown, appStore, title } = props
  const vm = useLocalObservable(() => new MealsListModalVM(props))

  useEffect(() => {
    vm.setShown(shown)
    appStore.setActiveModal('MealsListModal', 56)
  }, [shown])

  return (
    <IonModal id='MealsListModal' isOpen={vm.shown} showBackdrop={true} onDidDismiss={() => vm.hide()}>
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => vm.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <MealsList />
    </IonModal>
  )
}

export default inject('rootStore', 'appStore')(observer(MealsListModal))
