import { Capacitor } from '@capacitor/core'
import { observable, action, makeObservable } from 'mobx'
import SubscribedTopic from './aggregate/SubscribedTopic'
import { RootStore } from 'src/stores/RootStore'
import { FCM } from '@capacitor-community/fcm'
import * as Sentry from '@sentry/browser'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications'
import { LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications'

export class NotificationsStore {
  private rootStore: RootStore
  private hasPermission: boolean = false
  private loaded: boolean

  constructor(rootStore: RootStore) {
    makeObservable(this)
    makePersistable(this, { name: 'NotificationsStore', properties: ['subscribedTopics'] }).then((st) => {
      let isHydrated = false
      if (process.env.NODE_ENV === 'test') isHydrated = true
      if (st && st.isHydrated) isHydrated = true
      if (isHydrated) this.onHydrationCompleted()
    })
    this.rootStore = rootStore
  }

  @action
  public onHydrationCompleted() {
    this.subscribedTopics = observable.array(
      this.subscribedTopics.map((e) => makeObservable(deserialize(SubscribedTopic, e)))
    )
  }

  @observable public subscribedTopics: Array<SubscribedTopic> = []

  public async processNotifications() {
    if (Capacitor.getPlatform() === 'web') return
    if (!this.rootStore.userStore.user) {
      setTimeout(() => this.processNotifications(), 500)
      return
    }
    if (this.loaded) return
    await this.ensurePermissions()
    await this.ensureFcmToken()
    const validTopic = this.getValidTopic()
    await this.subscribeToValidTopic(validTopic)
    await this.unsubscribeFromInvalidTopics(validTopic)
    this.listenForNotification()
    this.loaded = true
  }

  private async ensurePermissions() {
    try {
      let permStatus = await PushNotifications.checkPermissions()
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions()
      }
      this.hasPermission = true
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
  }

  private async subscribeToValidTopic(validTopic: string, attempts: number = 0) {
    if (!this.hasPermission) return
    try {
      const result = await FCM.subscribeTo({ topic: validTopic })
      this.saveSubscribedTopic(validTopic)
    } catch (e) {
      if (String(e.message).includes("Can't subscribe to topic") && attempts < 3) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        await this.subscribeToValidTopic(validTopic, attempts + 1)
        return
      }
      Sentry.captureException(e)
    }
  }

  private async unsubscribeFromInvalidTopics(validTopic) {
    for (let topic of this.subscribedTopics) {
      if (topic.path === validTopic) continue
      await this.unsubscribeFromTopic(topic.path)
    }
  }

  private async unsubscribeFromTopic(topicPath) {
    await FCM.unsubscribeFrom({ topic: topicPath })
    this.deleteTopic(topicPath)
  }

  public async unsubscribeFromAllTopics() {
    if (!this.hasPermission) return
    for (let topic of this.subscribedTopics) {
      await this.unsubscribeFromTopic(topic.path)
    }
  }

  private getValidTopic(): string {
    let appMode = 'prod'
    if (process.env.REACT_APP_IS_DEV_MODE === '1') appMode = 'dev'
    return appMode + '_user_' + this.rootStore.userStore.user.IdentityId
  }

  @action
  private saveSubscribedTopic(topicPath) {
    const foundTopic = this.subscribedTopics.find((e) => e.path === topicPath)
    if (foundTopic) return
    this.subscribedTopics.push(SubscribedTopic.create(topicPath))
  }

  private async ensureFcmToken() {
    await PushNotifications.register()
  }

  private listenForNotification() {
    if (!this.hasPermission) return
    if (this.rootStore.appStore.isIosNative) return
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      this.showLocalNotification(notification)
    })
    // PushNotifications.addListener('registrationError', (error: any) => {
    //   this.hasPermission = false
    // })
    // PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
    //   console.log('Push action performed: ' + JSON.stringify(notification))
    // })
  }

  private showLocalNotification(notification: PushNotificationSchema) {
    const notif: LocalNotificationSchema = {
      title: notification.title,
      body: notification.body,
      id: 1,
      schedule: { at: new Date(Date.now() + 1000) },
      sound: null,
      attachments: null,
      actionTypeId: '',
      smallIcon: 'ic_notification',
      extra: {
        icon: 'res://drawable-xhdpi/ic_notification.png',
        smallIcon: 'res://drawable-xhdpi/ic_notification.png',
      },
    }

    LocalNotifications.schedule({
      notifications: [notif],
    })
  }

  @action
  public deleteTopic(path) {
    this.subscribedTopics.splice(this.getTopicIndex(path), 1)
  }

  private getTopicIndex(path): number {
    return this.subscribedTopics.findIndex((e) => e.path === path)
  }
}
