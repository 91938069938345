import React from 'react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { RootStore } from '../../../stores/RootStore'
import { match } from 'react-router'
import BotSessionEditPageContent from './BotSessionEditPageContent'
import { BotSessionsStore } from '../../store/BotSessionsStore'

interface Props {
  botSessionsStore: BotSessionsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('botSessionsStore')
@inject('appStore')
@observer
export default class BotSessionEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/botsessionedit/' + this.props.match.params['id'])
  }

  render() {
    return (
      <>
        <BotSessionEditPageContent botSessionGuid={this.props.match.params['id']} />
      </>
    )
  }
}
