import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAlert,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { close, add, trashOutline, checkmarkSharp } from 'ionicons/icons'
import { RootStore } from 'src/stores/RootStore'
import { MealsStore } from '../../store/MealsStore'
import DetailsForm from './DetailsForm'
import RecipeCards from './RecipeCards'
import ItemActionSheet from './ItemActionSheet'
import RecipeSelectModal from './RecipeSelectModal'
import ItemsSelectModal from 'src/items-select/views/ItemsSelectModal'
import RecipeMenu from './RecipeMenu'
import RecipeItemEditModal from 'src/recipes/views/edit/RecipeItemEditModal'
import BackIcon from 'src/shared/BackIcon'

interface Props {
  mealsStore: MealsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: any
}

@inject('mealsStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class MealEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let qs = this.props.match.params['id']
    if (this.props.match.params['date']) qs += '/' + this.props.match.params['date']
    if (this.props.match.params['catid']) qs += '/' + this.props.match.params['catid']
    this.props.appStore.setCurrentRoute('/mealedit/' + qs)

    this.props.mealsStore.lazyLoadEditVM(
      this.props.match.params['id'],
      this.props.match.params['date'],
      this.props.match.params['catid'],
      this.props.match.params['recipeid']
    )
  }

  componentWillUnmount() {
    this.props.mealsStore.lazyLoadEditVM('empty')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  renderDeleteConfirm() {
    const { editVM } = this.props.mealsStore
    if (!editVM) return null
    return (
      <IonAlert
        isOpen={editVM.deleteConfirmShown}
        onDidDismiss={() => editVM.hideDeleteConfirm()}
        header={'Delete Meal'}
        message={'Are you sure you wish to delete this meal?'}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => editVM.hideDeleteConfirm(),
          },
          {
            text: 'Yes',
            handler: () => editVM.delete(),
          },
        ]}
      />
    )
  }

  renderAddButtons() {
    const { editVM } = this.props.mealsStore
    return (
      <>
        <div className='btn-container'>
          <div className='btn' onClick={() => editVM.showAddRecipeModal()}>
            <IonIcon icon={add} size='small' />
            <div>Add Recipe</div>
          </div>
          <div className='btn' onClick={() => editVM.showAddItemModal()}>
            <IonIcon icon={add} size='small' />
            <div>Add Items</div>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { editVM } = this.props.mealsStore
    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='MealEditPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => editVM.back()}>
                <BackIcon />
              </IonButton>
            </IonButtons>
            <IonTitle>{editVM && editVM.pageTitle}</IonTitle>

            <IonButtons slot='end'>
              {editVM && editVM.isNewMeal && (
                <IonButton onClick={() => editVM.back()}>
                  <IonIcon icon={close} />
                </IonButton>
              )}
              {editVM && !editVM.isNewMeal && (
                <IonButton onClick={() => editVM.showDeleteConfirm()}>
                  <IonIcon icon={trashOutline} />
                </IonButton>
              )}
              <IonButton onClick={() => editVM.save()}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='MealEditPage_Content'
          scrollY
          ref={(e) => this.props.appStore.applyScrollStyles(e)}
          style={{ height: this.props.appStore.listHeightPx }}
        >
          <DetailsForm />
          <RecipeCards />
          {this.renderAddButtons()}
          <RecipeSelectModal />
          {editVM && <ItemsSelectModal itemsList={editVM.itemsList} />}
          <br />
          <br />
          {this.renderDeleteConfirm()}
          <ItemActionSheet />
          <RecipeMenu />
          <RecipeItemEditModal />
          <br />
        </IonContent>

        <IonFab vertical='bottom' horizontal='end'>
          <IonFabButton
            color='tertiary'
            onClick={() => editVM.save()}
            disabled={editVM && !editVM.isValid}
            style={{
              marginTop: '-60px',
              marginLeft: '-60px',
            }}
          >
            <IonIcon icon={checkmarkSharp} />
          </IonFabButton>
        </IonFab>
      </>
    )
  }
}
