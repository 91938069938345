import { Recipe } from '../../../recipes/aggregate/Recipe'
import { RecipesStore } from 'src/recipes/store/RecipesStore'
import { RootStore } from 'src/stores/RootStore'
import { autorun, reaction, computed, observable, action, makeObservable } from 'mobx'
import { MealRecipe } from 'src/meals/aggregate/MealRecipe'
import { MealEditVM } from './MealEditVM'
import { MealItemVM } from './MealItemVM'
import { RecipeItemRowVM } from './RecipeItemRowVM'

export class MealRecipeCardVM {
  private rootStore: RootStore
  private mealRecipe: MealRecipe
  private editVM: MealEditVM

  constructor(rootStore: RootStore, editVM: MealEditVM, mealRecipe: MealRecipe) {
    makeObservable(this)
    this.rootStore = rootStore
    this.mealRecipe = mealRecipe
    this.editVM = editVM
  }

  @computed
  public get name(): string {
    if (!this.rootStore.recipesStore) return ''
    const foundRcp = this.rootStore.recipesStore.get(this.mealRecipe.RecipeGuid)
    if (foundRcp) return foundRcp.Name
    return ''
  }

  @computed
  public get mealRecipeGuid(): string {
    return this.mealRecipe.MealRecipeGuid
  }

  @computed
  public get recipeGuid() {
    return this.mealRecipe.RecipeGuid
  }

  @computed
  public get rank() {
    return this.mealRecipe.Rank
  }

  @computed
  public get recipe() {
    return this.rootStore.recipesStore.get(this.recipeGuid)
  }

  public getRecipeItems(recipeGuid: string): Array<RecipeItemRowVM> {
    if (!this.recipe) return []
    return this.recipe.RecipeItems.filter(e => !e.IsDeleted && e.hasDescription)
      .sort((a, b) => (a.Rank < b.Rank ? -1 : 1))
      .map(e => new RecipeItemRowVM(this.rootStore, this.editVM, this, e))
  }
}
