import { RootStore } from 'src/stores/RootStore'
import { computed, observable, action, makeObservable } from 'mobx'
import { MealEditVM } from './MealEditVM'
import { MealItem } from 'src/meals/aggregate/MealItem'
import { ListItemsListVM } from 'src/list-items/view-models/ListItemsListVM'

export class MealItemVM {
  private rootStore: RootStore
  private mealItem: MealItem

  constructor(rootStore: RootStore, editVM: MealEditVM, mealItem: MealItem) {
    makeObservable(this)
    this.rootStore = rootStore
    this.mealItem = mealItem
  }

  @observable public inConfirmation: boolean = false

  @computed
  public get listVM(): ListItemsListVM {
    return this.rootStore.listItemsStore.listVM
  }

  @computed
  public get quantity(): number {
    return this.listVM.getItemQuantity(this.itemGuid)
  }

  @computed
  public get hasQuantity(): boolean {
    return this.quantity !== 0
  }

  @computed
  public get isGotten(): boolean {
    return this.listVM.getIsGotten(this.itemGuid)
  }

  @computed
  public get isCleared(): boolean {
    return this.listVM.getIsCleared(this.itemGuid)
  }

  @action
  public toggle() {
    if (!this.hasQuantity) this.increaseQuantity()
    else if (this.hasQuantity && !this.isGotten) this.listVM.toggleGotten(this.itemGuid)
    else if (this.hasQuantity && this.isGotten) this.listVM.markAsCleared(this.itemGuid)
    else this.listVM.removeQuantity(this.itemGuid)
  }

  @action
  public increaseQuantity() {
    this.listVM.increaseQuantity(this.itemGuid)
  }

  @action
  public decreaseQuantity() {
    this.listVM.decreaseQuantity(this.itemGuid)
  }

  @action
  public removeQuantity() {
    this.listVM.removeQuantity(this.itemGuid)
  }

  @computed
  public get name(): string {
    if (!this.rootStore.itemsStore) return ''
    const foundItem = this.rootStore.itemsStore.get(this.mealItem.ItemGuid)
    if (!foundItem) return ''
    return foundItem.Name
  }

  @computed
  public get categoryColor(): string {
    if (!this.rootStore.categoriesStore) return ''
    const foundItem = this.rootStore.itemsStore.get(this.itemGuid)
    if (!foundItem) return ''
    const foundCat = this.rootStore.categoriesStore.get(foundItem.CategoryGuid)
    if (!foundCat) return ''
    return foundCat.Color
  }

  @computed
  public get categoryName(): string {
    if (!this.rootStore.categoriesStore) return ''
    const foundItem = this.rootStore.itemsStore.get(this.itemGuid)
    if (!foundItem) return ''
    const foundCat = this.rootStore.categoriesStore.get(foundItem.CategoryGuid)
    if (!foundCat) return ''
    return foundCat.Name
  }

  @computed
  public get sortName(): string {
    return this.categoryName + this.name
  }

  @computed
  public get iconName(): string {
    if (this.isGotten) return 'checkmark'
    if (this.hasQuantity && !this.isGotten && !this.isCleared) return 'cart'
    return 'add'
  }

  @action
  public goToEditPage() {
    this.rootStore.appStore.history.push('/itemedit/' + this.itemGuid)
  }

  @computed
  public get recipeGuid(): string {
    return this.mealItem.RecipeGuid
  }

  @computed
  public get mealItemGuid(): string {
    return this.mealItem.MealItemGuid
  }

  @computed
  public get itemGuid(): string {
    return this.mealItem.ItemGuid
  }

  @computed
  public get rank(): number {
    return this.mealItem.Rank
  }

  @action
  public delete() {
    this.mealItem.markAsDeleted()
  }

  @action
  public markForConfirmation(): void {
    this.inConfirmation = true
  }

  @action
  public doneConfirmation(): void {
    this.inConfirmation = false
  }

  public mealItemsForRecipe(): Array<MealItemVM> {
    throw new Error('Method not implemented.')
  }
}
