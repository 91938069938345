import { MealRecipe } from './MealRecipe'
import { IMealDTO } from '../dtos/IMealDTO'
import { persist } from 'mobx-persist'
import { observable, action, computed, makeObservable } from 'mobx'
import generateUUID from '../../utils/UUID'
import moment from 'moment'
import { MealItem } from './MealItem'
import { serializable, deserialize, serialize } from 'serializr'

export class Meal implements IMealDTO {
  public static create(boardId: number, mealGuid: string = null) {
    const meal = new Meal()
    meal.MealGuid = mealGuid
    if (!mealGuid) {
      meal.MealGuid = generateUUID()
      meal.IsNew = true
    }
    meal.BoardId = boardId
    meal.StatusId = 1
    meal.PlannedDateTime = moment()
      .utc()
      .toISOString()
    return meal
  }

  constructor() {
    makeObservable(this)
  }

  @persist @observable public MealGuid: string = ''
  @persist @observable public BoardId: number = 0
  @persist @observable public RecipeGuid: string = ''
  @persist @observable public MealCategoryGuid: string = ''
  @persist @observable public PlannedDateTime: string = ''
  @persist @observable public SpecificTime: boolean = false
  @persist @observable public Description: string = ''
  @persist @observable public Notes: string = ''
  @persist @observable public StatusId: number = 0
  @persist('list', MealRecipe) @observable public MealRecipes: Array<MealRecipe> = []
  @persist('list', MealItem) @observable public MealItems: Array<MealItem> = []
  @persist @observable public IsDeleted: boolean = false
  public isOnServer: boolean = false
  @serializable @observable public IsNew: boolean = false

  public markIsOnServer() {
    this.isOnServer = true
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  public get PlannedDateAsDateForQuickSort(): Date {
    return new Date(this.PlannedDateTime)
  }

  public get PlannedDateDayIndex(): number {
    const idx = moment
      .utc(this.PlannedDateTime)
      .toDate()
      .getDay()
    if (idx === 0) return 7
    return idx
  }

  @computed
  public get PlannedDateFormatted(): string {
    return moment(this.PlannedDateTime).format('MMDDYYYY')
  }

  @computed
  public get PlannedTime12Hr(): string {
    if (!this.SpecificTime) return ''
    let val = moment
      .utc(this.PlannedDateTime)
      .local()
      .format('h:mma')
    val = val.replace(':00', '')
    val = val.replace('m', '')
    val = val.replace('p', '')
    return val
  }

  @action
  public deleteRecipe(recipeGuid: string) {
    const items = this.getMealItemsForRecipe(recipeGuid)
    items.forEach(e => this.deleteItem(e.MealItemGuid))
    const rcp = this.MealRecipes.find(e => e.RecipeGuid === recipeGuid)
    if (this.IsNew) {
      const idx = this.MealRecipes.findIndex(e => e.RecipeGuid === recipeGuid)
      if (idx === -1) return
      this.MealRecipes.splice(idx, 1)
      return
    }
    rcp.markAsDeleted()
  }

  @action
  public deleteItem(mealItemGuid: string): void {
    const mi = this.MealItems.find(e => e.MealItemGuid === mealItemGuid)
    mi.markAsDeleted()
  }

  public getMealItemsForRecipe(recipeGuid: string): Array<MealItem> {
    return this.MealItems.filter(e => e.RecipeGuid === recipeGuid && !e.IsDeleted)
  }

  public getMealItem(itemGuid: string): MealItem {
    return this.MealItems.find(e => e.ItemGuid === itemGuid)
  }

  @computed
  public get isInFuture(): boolean {
    return moment.utc(this.PlannedDateTime).isSameOrAfter(moment().startOf('day'))
  }

  @action
  public setRecipe(guid: string) {
    this.RecipeGuid = guid
  }

  @action
  public setMealCategory(guid: string) {
    this.MealCategoryGuid = guid
  }

  @action
  public setPlannedDateTime(val: moment.Moment) {
    if (!val.isUTC) val.utc()
    this.PlannedDateTime = val.toISOString()
  }

  @computed
  public get localPlannedDateTime(): moment.Moment {
    return moment
      .utc(this.PlannedDateTime)
      .local()
      .clone()
  }

  @action
  public disableSpecificTime() {
    this.SpecificTime = false
      // this.PlannedDateTime = this.localPlannedDateTime
      //   .clone()
      //   .startOf('day')
      //   .clone()
      //   .utc()
      //   .toISOString()
  }

  @action
  public toggleSpecificTime(defaultTime?: string) {
    this.SpecificTime = !this.SpecificTime
    if (defaultTime) {
      const vals = defaultTime.split(':')
      const hrs = vals[0]
      const mins = vals[1]
      this.PlannedDateTime = moment
        .utc(this.PlannedDateTime)
        .local()
        .startOf('day')
        .add(hrs, 'hours')
        .add(mins, 'minutes')
        .utc()
        .toISOString()
      return
    }
    const hrs = moment().hours()
    const mins = moment().minutes()
    this.PlannedDateTime = moment
      .utc(this.PlannedDateTime)
      .local()
      .startOf('day')
      .add(hrs, 'hours')
      .add(mins, 'minutes')
      .utc()
      .toISOString()
  }

  @action
  public setDescription(val: any) {
    this.Description = val
  }

  @action
  public addRecipe(recipeGuid) {
    const mr = MealRecipe.create(recipeGuid)
    mr.setRank(this.MealRecipes.length + 1)
    this.MealRecipes.push(mr)
  }

  @action
  public addItem(itemGuid, recipeGuid = null, qty: number = 0) {
    const mi = MealItem.create(itemGuid, recipeGuid)
    if (qty !== 0) mi.setQuantity(qty)
    this.MealItems.push(mi)
  }

  public clone(): Meal {
    return deserialize(Meal, serialize(this))
  }

  public toDTO(): IMealDTO {
    return serialize(this)
  }
}
